import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
  Flex, Icon, Text, useColorModeValue, Box, Modal, ModalOverlay, ModalContent, Image, Button, Input
  , FormLabel, Select
} from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
import gradient2 from "../../../../../../assets/img/service/gradient2.png";
import defaultLogoImg from "../../../../../../assets/img/service/eternalsCat.png";

import Card from "../../../../../../components/card/Card";
import { MdDelete } from "react-icons/md";

export default function ItemUpdateModal({
  titleText, onClose, itemInfo, userInfo, handleAddToCart, setUploadItem, bigCategoryList,
  totalDiscountAmount, totalDiscountPercent,
  bigCategoryOptionGroupList, optionGroupList, cardRelod
}) {
  const [update, setUpdate] = useState(false);
  const [imageCheck, setImageCheck] = useState(false);
  const [preview, setPreview] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [productImage, setProductImage] = useState();
  const [btnText, setBtnText] = useState("확인");
  const [categoryBigName, setCategoryBigName] = useState('');
  const [stockOpen, setStockOpen] = useState(false);

  const bgHover = useColorModeValue({ bg: "secondaryGray.400" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const textStyle = {
    fontWeight: '400',
    fontSize: { base: '14px' }
  };

  useEffect(() => {
    const category = bigCategoryList.find(element => element.category_big_id === itemInfo.category_big_id);
    setCategoryBigName(category ? category.category_big_name : '');
    if (itemInfo.stock !== 'infinity') setStockOpen(true);
    setInputs({ item_name: "", price: "", stock: "", comment: "", bigCategory: "" });
  }, [itemInfo, bigCategoryList]);

  const [inputs, setInputs] = useState({
    name: "",
    price: "",
    stock: "",
    comment: "",
    bigCategory: "",
    option_group: ""
  });

  const { name, price, stock, comment, bigCategory, option_group } = inputs;

  const toggleUpdate = () => setUpdate(prev => !prev);
  const toggleChangeImage = () => setChangeImage(prev => !prev);
  const toggleSwitch = () => setStockOpen(prev => !prev);

  function onChange(e) {
    const { value, name } = e.target;
    setInputs(prev => ({ ...prev, [name]: value }));
  }

  function onChangeImage(fileBlob) {
    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result);
      setProductImage(fileBlob);
      setImageCheck(false);
      setBtnText("확인");
    };
    reader.readAsDataURL(fileBlob);
  }

  function checkProfile() {
    if (!productImage) {
      if (window.confirm("이미지를 빈 값으로 하시겠습니까?")) {
        setImageCheck(true);
      } else {
        setBtnText("이미지를 확인하세요.");
        return;
      }
    } else {
      if (productImage.size > 10 * 1024 * 1024) {
        setBtnText("10MB 이하만 가능합니다.");
        return;
      }
    }
    setBtnText("완료");
    setImageCheck(true);
  }


  // 초기 값 세팅 저장 ------------------------------------------------------------------
  const [initialInputs, setInitialInputs] = useState({
    name: "",
    price: "",
    stock: "",
    comment: "",
    bigCategory: "",
    option_group: [],
  });

  useEffect(() => {
    setInitialInputs({
      name: itemInfo.name || "",
      price: itemInfo.price || "",
      stock: itemInfo.stock || "",
      comment: itemInfo.comment || "",
      bigCategory: itemInfo.category_big_id || "",
      option_group: bigCategoryOptionGroupList
        .filter((element) => element.product_number === itemInfo.product_number && element.option_group_id !== null)
        .reduce((acc, current) => {
          const x = acc.find(item => item.option_group_id === current.option_group_id);
          if (!x) {
            return acc.concat([current.option_group_id]);
          } else {
            return acc;
          }
        }, []),
    });

    setInputs({
      name: itemInfo.name || "",
      price: itemInfo.price || "",
      stock: itemInfo.stock || "",
      comment: itemInfo.comment || "",
      bigCategory: itemInfo.category_big_id || "",
      option_group: bigCategoryOptionGroupList
        .filter((element) => element.product_number === itemInfo.product_number && element.option_group_id !== null)
        .reduce((acc, current) => {
          const x = acc.find(item => item.option_group_id === current.option_group_id);
          if (!x) {
            return acc.concat([current.option_group_id]);
          } else {
            return acc;
          }
        }, []),
    });

    setOptionGroupList(initialInputs.option_group);
  }, [itemInfo, bigCategoryOptionGroupList]);

  //  / 초기 값 세팅 저장 ------------------------------------------------------------------

  function saveUpdate(e) {
    e.preventDefault();




console.log(imageCheck)
console.log(productImage)

    // 변경된 부분이 있는지 확인
    const hasChanges =
      initialInputs.name !== name ||
      initialInputs.price !== price ||
      initialInputs.stock !== stock ||
      initialInputs.comment !== comment ||
      initialInputs.bigCategory !== bigCategory ||
      JSON.stringify(initialInputs.option_group) !== JSON.stringify(inputOptionGroupList) ||
      (imageCheck);

    if (!hasChanges) {
      alert("변경된 사항이 없습니다.");
      return;
    }

    if (!window.confirm("수정하시겠습니까?")) return;

    const formData = new FormData();
    formData.append("product_number", itemInfo.product_number);

    let categoryID = bigCategoryList.find(
      (element) => element.category_big_name === bigCategory
    )?.category_big_id;
    if (!categoryID) categoryID = itemInfo.category_big_id;
    formData.append("category_big_id", categoryID);

    if (imageCheck && productImage) formData.append("image", productImage);
    if (name) formData.append("name", name);
    if (price) formData.append("price", price);
    if (comment) formData.append("content", comment);
    formData.append("stock", stockOpen && stock ? stock : "infinity");

    // 추가된 옵션 그룹을 JSON으로 저장
    if (inputOptionGroupList.length !== 0) {
      formData.append('option_group', JSON.stringify(inputOptionGroupList));
    }

    axios
      .post("/api/pos/product/update", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response.data === 1 || response.data === true) {
          alert("수정이 완료되었습니다.");
          // setUploadItem(true);
          cardRelod();
          onClose();
        }
      })
      .catch(() => alert("다시 시도해주세요."));
  }



  function deleteProduct() {
    if (!window.confirm("삭제하시겠습니까?")) return;

    axios.post('/api/pos/product/delete', { product_number: itemInfo.product_number })
      .then(response => {
        if (response.data === 1 || response.data === true) {
          alert("삭제가 완료되었습니다.");
          setUploadItem(true);
          onClose();
        }
      }).catch(() => alert("다시 시도해주세요."));
  }

  function salesOnOff() {
    const formData = new FormData();
    formData.append('product_number', itemInfo.product_number);
    formData.append('stock', itemInfo.stock === '0' ? 'infinity' : '0');

    axios.post('/api/pos/product/update', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => {
        if (response.data === 1 || response.data === true) {
          alert("수정이 완료되었습니다.");
          setUploadItem(true);
          onClose();
        }
      })
      .catch(() => alert("다시 시도해주세요."));
  }

  const [inputOptionGroupList, setOptionGroupList] = useState([]);
  const inputBgColor = useColorModeValue("white", "gray.700"); // 입력 필드 배경색 설정
  const inputBorderColor = useColorModeValue("gray.300", "gray.600"); // 입력 필드 테두리 색상
  const inputTextColor = useColorModeValue("black", "white"); // 입력 필드 텍스트 색상


  useEffect(() => {
    const filteredOptionGroups = bigCategoryOptionGroupList
      .filter((element) =>
        element.product_number === itemInfo.product_number &&
        element.option_group_id !== null
      )
      .reduce((acc, current) => {
        const x = acc.find(item => item.option_group_id === current.option_group_id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

    setOptionGroupList(filteredOptionGroups.map(group => ({ option_group: group.option_group_id })));

  }, [bigCategoryOptionGroupList, itemInfo.product_number]);

  useEffect(() => {
    if (update && optionGroupList.length > 0) {
      setInputs(prev => ({ ...prev, option_group: optionGroupList[0].option_group_id }));
    }
  }, [update, optionGroupList]);

  function addOptionGroupPlus() {

    if (!option_group) {
      alert("옵션을 선택하세요.");
      return;
    }

    if (option_group && !inputOptionGroupList.some(item => item.option_group === option_group)) {
      setOptionGroupList(prevOptionGroupList => [...prevOptionGroupList, { option_group }]);
      setInputs({ ...inputs, option_group: "" });
    }

  }
  function minusOptionGroup(e) {
    inputOptionGroupList.splice(e.target.value, 1);
    setOptionGroupList([...inputOptionGroupList]);
  }
  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalOverlay />
        <ModalContent
          my='auto'
          border='1px solid'
          borderColor='white'
          borderRadius='10px'
          p={{ base: '5%', md: '2%', xl: '1%' }}
        >
          <Flex mb='3%' fontSize='150%' align='center'>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} w='19px' h='24px' onClick={onClose} color='#245EC6' as={BsArrowLeft} />
            <Text fontWeight='700' ml='10px' fontSize='20px'>{titleText}</Text>
          </Flex>
          <Box border='1px solid' borderColor='gray.400' p='3%'>
            <Box m='5%'>
              <Flex mb="10px">
                {changeImage ?
                  <Box align='center'>

                    {preview === "" ?
                      <Image maxH='100%' width='auto' src={defaultLogoImg} alt="preview-img" />
                      :
                      <Image maxH='100%' width='auto' src={preview} alt="preview-img" />
                    }
                    {!imageCheck && <Input
                      width='80%'
                      fontSize='9px'
                      type='file'
                      mr='5px'
                      fontWeight='500'
                      autoComplete='off'
                      accept="image/*"
                      onChange={(e) => onChangeImage(e.target.files[0])}
                    />}
                    <Button
                      backgroundImage={gradient2}
                      backgroundRepeat='no-repeat'
                      bgSize='cover'
                      width={imageCheck ? '40%' : '50%'}
                      textColor='white'
                      disabled={imageCheck}
                      onClick={checkProfile}
                    >
                      {btnText}
                    </Button>
                  </Box>
                  :
                  <Image src={itemInfo.image ? `data:image/*;base64,${itemInfo.image}` : defaultLogoImg} alt='' className='storeItemImage' />
                }
              </Flex>
              <Flex mb="10px">
                <Text isTruncated className={textStyle} minW="80px">카테고리&nbsp;:&nbsp;</Text>
                <Box ml={2} w="100%">
                  {update ?
                    <Input
                      ml='auto'
                      width='100%'
                      autoComplete="off"
                      className={textStyle}
                      placeholder={categoryBigName}
                      list={'bigCategoryList'}
                      id="bigCategory"
                      name="bigCategory"
                      value={categoryBigName}
                      onChange={onChange}
                    />
                    :
                    <Text isTruncated className={textStyle}>{categoryBigName}</Text>
                  }
                </Box>
              </Flex>
              <Flex mb="10px">
                <Text isTruncated className={textStyle} minW="80px">상품명&nbsp;:&nbsp;</Text>
                <Box ml={2} w="100%">
                  {update ?
                    <Input
                      ml='auto'
                      autoComplete="off"
                      className={textStyle}
                      onChange={onChange}
                      id="name"
                      name="name"
                      placeholder={itemInfo.name}
                      width='100%'
                    />
                    :
                    <Text isTruncated className={textStyle}>{itemInfo.name}</Text>
                  }
                </Box>
              </Flex>
              <Flex mb="10px">
                <Text isTruncated className={textStyle} minW="80px">판매가&nbsp;:&nbsp;</Text>
                <Box ml={2} w="100%">
                  {update ?
                    <Input
                      ml='auto'
                      autoComplete="off"
                      className={textStyle}
                      onChange={onChange}
                      id="price"
                      name="price"
                      placeholder={itemInfo.price}
                      width='100%'
                    />
                    :
                    <>
                      {totalDiscountAmount || totalDiscountPercent ?
                        <Flex>
                          <Text color='red' textDecoration='line-through'>{itemInfo.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</Text>
                          <Text>&nbsp;→&nbsp;</Text>
                          <Text>
                            {(Number(itemInfo.price) - Number(totalDiscountAmount) > 0
                              ? (Number(itemInfo.price) - Number(totalDiscountAmount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              : 0) || 0} 원
                          </Text>
                        </Flex>
                        :
                        <Text isTruncated className={textStyle}>{itemInfo.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</Text>
                      }
                    </>
                  }
                </Box>
              </Flex>
              <Flex mb="10px">
                <Text isTruncated className={textStyle} minW="80px">상품 설명&nbsp;:&nbsp;</Text>
                <Box ml={2} w="100%">
                  {update ?
                    <Input
                      ml='auto'
                      autoComplete="off"
                      placeholder={itemInfo.comment}
                      className={textStyle}
                      onChange={onChange}
                      id="comment"
                      name="comment"
                      width='100%'
                    />
                    :
                    <Text className={textStyle}>{itemInfo.comment}</Text>
                  }
                </Box>
              </Flex>
              <Flex mb="10px">
                <Text isTruncated className={textStyle} minW="80px">적용 옵션&nbsp;:&nbsp;</Text>
                <Box ml={2} w="100%">
                  {update ? (
                    <>
                      <Flex>
                        <Select
                          fontSize="15px"
                          value={option_group}
                          id="option_group"
                          name="option_group"
                          onChange={onChange}
                          bg={inputBgColor} // 입력 필드 배경색
                          borderColor={inputBorderColor} // 입력 필드 테두리 색상
                          color={inputTextColor} // 입력 필드 텍스트 색상
                        >
                          <option value="" disabled>
                            미 선택 옵션 중 선택
                          </option>
                          {optionGroupList
                            .map((group) => (
                              <option key={group.option_group_id} value={group.option_group_id}>
                                {group.option_group_name} (
                                {group.option_group_type === "required"
                                  ? "필수 선택"
                                  : "선택 옵션"}
                                )
                              </option>

                            ))}
                        </Select>
                        <Button ml={2} onClick={addOptionGroupPlus}>
                          추가
                        </Button>
                      </Flex>
                      <Box mt={2}>
                        {inputOptionGroupList.map((optionGroup, index) => (
                          <Flex key={index} justify="space-between" border='1px solid' borderColor='gray.200' p={2} mb={2}>
                            <Text>
                              { // 선택된 옵션의 group id 와 일치하는 optionGroupList 에서 찾고 그 내용 화면에 뿌림 
                                optionGroupList
                                  .filter(group => group.option_group_id === optionGroup.option_group)
                                  .map(filteredGroup => (
                                    <React.Fragment key={filteredGroup.option_group_id}>
                                      {filteredGroup.option_group_name} ({filteredGroup.option_group_type === 'required' ? "필수 선택" : "선택 옵션"})
                                    </React.Fragment>
                                  ))
                              }
                            </Text>
                            <Button
                              size="sm"
                              colorScheme="red"
                              onClick={() => minusOptionGroup({ target: { value: index } })}
                            >
                              제거
                            </Button>
                          </Flex>
                        ))}
                      </Box>
                    </>
                  ) : null}
                  {!update && bigCategoryOptionGroupList
                    .filter((element, index, self) =>
                      itemInfo.product_number === element.product_number &&
                      index === self.findIndex(
                        (e) => e.option_group_id === element.option_group_id && e.product_number === element.product_number
                      )
                    )
                    .map((item, itemIndex) => (
                      <React.Fragment key={itemIndex}>
                        {item.option_group_id ?
                          <Flex
                            justify="space-between"
                            border="1px solid"
                            borderColor="gray.200"
                            p={2}
                            mb={2}
                            w="100%"
                          >
                            <Text w="100%">
                              {item.option_group_name} ({item.option_group_type === 'required' ? '필수 선택' : '선택 옵션'})
                            </Text>
                          </Flex>
                          : null}
                      </React.Fragment>
                    ))}


                </Box>
              </Flex>
              {userInfo.authority === 'manager' || userInfo.authority === 'master' ?
                <>
                  <Flex mb="10px">
                    <Text isTruncated className={textStyle} minW="80px">수량&nbsp;:&nbsp;</Text>
                    <Box ml={2} w="100%">
                      {update ?
                        <>
                          {stockOpen ?
                            <Input
                              ml='auto'
                              autoComplete="off"
                              placeholder={itemInfo.stock === "infinity" ? "0" : itemInfo.stock}
                              className={textStyle}
                              onChange={onChange}
                              id="stock"
                              name="stock"
                              fontSize='15px'
                              height='40px'
                              // textAlign='right'
                              width='50%'
                            />
                            :
                            <Input
                              ml='auto'
                              fontSize='15px'
                              height='40px'
                              width='50%'
                              fontWeight='500'
                              placeholder="제한없음"
                              // textAlign='right'
                              disabled
                            />
                          }
                          <Button backgroundColor='#ccc' className={`switch ${stockOpen ? 'on' : 'off'}`} onClick={toggleSwitch} my='auto'>
                            <div className="slider"></div>
                          </Button>
                        </>
                        :
                        <Text isTruncated className={textStyle}>{itemInfo.stock === "infinity" ? "수량 제한 없음" : itemInfo.stock}</Text>
                      }
                    </Box>
                  </Flex>
                  <Box align='center'>
                    {update ?
                      <>
                        <Flex mt='5%' justifyContent='space-between'>
                          <Button
                            className=" main-btn-color"
                            backgroundRepeat='no-repeat'
                            bgSize='cover'
                            width='40%'
                            textColor='white'
                            onClick={saveUpdate}
                          >
                            저장
                          </Button>
                          <Button
                            className=" main-btn-color"
                            backgroundRepeat='no-repeat'
                            bgSize='cover'
                            width='40%'
                            textColor='white'
                            onClick={salesOnOff}
                          >
                            {itemInfo.stock === '0' ? "판매개시" : "판매중단"}
                          </Button>
                        </Flex>
                        <Flex mt='5%' justifyContent='space-between'>
                          <Button
                            className=" main-btn-color"
                            backgroundRepeat='no-repeat'
                            bgSize='cover'
                            width='40%'
                            textColor='white'
                            onClick={toggleChangeImage}
                          >
                            이미지
                          </Button>
                          <Button
                            className=" main-btn-color"
                            backgroundRepeat='no-repeat'
                            bgSize='cover'
                            width='40%'
                            textColor='white'
                            onClick={deleteProduct}
                          >
                            삭제
                          </Button>
                        </Flex>
                      </>
                      :
                      <Button
                        className=" main-btn-color"
                        backgroundRepeat='no-repeat'
                        bgSize='cover'
                        width='80%'
                        mt='5%'
                        textColor='white'
                        onClick={toggleUpdate}
                      >
                        수정하기
                      </Button>
                    }
                  </Box>
                </>
                :
                itemInfo.stock === '0' ?
                  <Box align='center'>
                    <Button
                      backgroundImage={gradient2}
                      backgroundRepeat='no-repeat'
                      bgSize='cover'
                      width='80%'
                      mt='5%'
                      textColor='white'
                      disabled
                    >
                      품절
                    </Button>
                  </Box>
                  :
                  null
              }
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
}
