import React, { useState, useEffect } from "react";
// External Libraries
import axios from 'axios';
import { Input, Text, useColorModeValue, Flex, Image, FormControl, Button } from "@chakra-ui/react";
// Assets
import '../../css/App.css';
import veta from "../../assets/img/service/veta_banana.png";
import POS_TEXT from "../../assets/img/service/POS_MAIN_LOGO_TEXT.png"

import Underbar from "../../components/underbar/Underbar";

// 암호화 js 
import CryptoJS from 'crypto-js';

/**
 * 
 * Props list - none
 */
export default function SignIn({ setViewComponent, setToken, setSseConnect }) {
  
  const [localIp, setLocalIp] = useState("");
  useEffect(() => {

    const getLocalIP = async () => {


      /*
      const pc = new RTCPeerConnection({ iceServers: [] });
      pc.createDataChannel('');
      pc.createOffer().then(sdp => pc.setLocalDescription(sdp));

      pc.onicecandidate = (ice) => {
        if (ice && ice.candidate && ice.candidate.candidate) {
          const ipMatch = /([0-9]{1,3}(\.[0-9]{1,3}){3})/.exec(ice.candidate.candidate);
          if (ipMatch) {
            // console.log("Local Pc 의 현재 IP Address: ", ipMatch[1]);
            setLocalIp(ipMatch[1]);
            const storedLoginData = JSON.parse(localStorage.getItem(ipMatch[1]));
            if (storedLoginData) {
              setInputs({
                storeNumber: storedLoginData.store_number,
                id: storedLoginData.id,
                pwd: storedLoginData.pwd
              });
            }
            pc.close();
          } else {
            console.log("No IP match found in candidate: ", ice.candidate.candidate);
          }
        }
      };
    */
   const userAgent = navigator.userAgent.toLowerCase();
   console.log(userAgent);
   if(userAgent){
      setLocalIp(userAgent);
              const storedLoginData = JSON.parse(localStorage.getItem(userAgent));
              if (storedLoginData) {
                setInputs({
                  storeNumber: storedLoginData.store_number,
                  id: storedLoginData.id,
                  pwd: storedLoginData.pwd
                });
              }
            }


    };

    getLocalIP();


    
  },[])
  
  
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const [inputs, setInputs] = useState({
    storeNumber: "",
    id: "",
    pwd: ""
  });
  const { storeNumber, id, pwd } = inputs;

  useEffect(() => {
    const storedStoreNumber = localStorage.getItem('store_number');
    const storedId = localStorage.getItem('id');
    if (storedStoreNumber || storedId) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        storeNumber: storedStoreNumber || "",
        id: storedId || ""
      }));
    }
  }, []);

  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  function hashVisitorId(visitorId) {
    return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
  }

  async function handleSubmit(e) {
    if(e !== ""){

      e.preventDefault();
    }

    axios.post('/api/store/connect', {
      store_number: storeNumber,
      id: id,
      pwd: hashVisitorId(pwd)
    }).then((response) => {
      if (response.data.error) {
        alert("올바른 번호를 입력하세요." + response.data.error);
      } else {
        const token = response.data.token;
        setToken(token);
        localStorage.setItem('jwtToken', JSON.stringify(token));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        localStorage.setItem('store_number', storeNumber);
        localStorage.setItem('store_name', response.data.store_name);
        localStorage.setItem('id', id);
        localStorage.setItem('loginUser', id);
        setViewComponent(response.data.page);
        setSseConnect(true);

        const loginData = { store_number: storeNumber, id: id, pwd: pwd };

        localStorage.setItem(localIp, JSON.stringify(loginData));
      }
    }).catch(error => {
      console.error("There was an error!", error);
    });
  }

  async function handleSubmitGoBackOffice(e) {
    e.preventDefault();

    axios.post('/api/store/connect', {
      store_number: storeNumber,
      id: id,
      pwd: hashVisitorId(pwd)
    }).then((response) => {
      if (response.data.error) {
        alert("올바른 번호를 입력하세요." + response.data.error);
      } else {
        const token = response.data.token;
        setToken(token);
        localStorage.setItem('jwtToken', JSON.stringify(token));
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        localStorage.setItem('store_number', storeNumber);
        localStorage.setItem('store_name', response.data.store_name);
        localStorage.setItem('id', id);
        localStorage.setItem('loginUser', id);
        setViewComponent('BackOffice');
        // setSseConnect(true);
      }
    }).catch(error => {
      console.error("There was an error!", error);
    });
  }


  useEffect(() => {
    try{
      if(localStorage.getItem(localIp) !== null){
        const storedLoginData = JSON.parse(localStorage.getItem(localIp));
        if(pwd === storedLoginData.pwd){
          handleSubmit("");
        }
      }
    }catch (error){
      console.error(error);
    }
  },[pwd])

  return (
    <Flex
      position='relative'
      h="100vh"
      w='100vw'
      // backgroundColor='#008BFF'
       className='main-background-color'
      >
      <Flex
        display='flex'
        className="login"
        justifyContent='start'
        direction='column'
      >
        <Flex justifyContent='center'
          direction='column' align='center'
          textColor='white'
           mt='10vh' >
          <Image src={POS_TEXT} maxH="20vh" />
  
        </Flex>
        <Flex
          height='35vh'
          direction='column'
          w="100%"
          justify='center'
          borderRadius='0px'
          mx={{ base: "auto", lg: "unset" }}
          mt="7vh"
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <Input
                className="loginText loginStoreIdInput"
                isRequired={true}
                variant='auth'
                type='text'
                backgroundColor='rgba(255,255,255,0.9)'
                width='100%'
                borderRadius='0'
                placeholder='가맹점 번호'
                fontWeight='400'
                height='50px !important'
                id="storeNumber"
                name="storeNumber"
                value={storeNumber}
                onChange={onChange} />
              <Input
                // mt='5vh'
                className="loginText loginStoreIdInput"
                isRequired={true}
                variant='auth'
                type='text'
                backgroundColor='rgba(255,255,255,0.9)'
                width='100%'
                height='50px !important'
                borderRadius='0'
                placeholder='아이디'
                fontWeight='400'
                id="id"
                name="id"
                value={id}
                onChange={onChange} />
              <Input
                className="loginText loginStoreIdInput"
                isRequired={true}
                variant='auth'
                type='password'
                backgroundColor='rgba(255,255,255,0.9)'
                width='100%'
                height='50px !important'
                borderRadius='0'
                placeholder='비밀번호'
                fontWeight='400'
                id="pwd"
                name="pwd"
                value={pwd}
                onChange={onChange} />

              <Button
                boxShadow={shadow}
                fontWeight='700'
                width='100%'
                float='right'
                type="submit"
                className='main-btn-color loginNextBtn'
              >
                <Text className="loginText" color='white'>
                  접속하기 &gt;
                </Text>
              </Button>

            </FormControl>

          </form>
        </Flex>
        <Button 
          position='fixed' 
          fontSize='400' 
          className="loginText loginSignUpBtn" 
          bottom='10vh' 
          backgroundColor='rgba(0,0,0,0.3)' 
          textColor='white'
           height='4vh'
          onClick={(e) => { handleSubmitGoBackOffice(e) }}>
          통계 페이지 이동 &gt;
        </Button>
        <Button 
        position='fixed' 
        fontSize='400' 
        className="loginText loginSignUpBtn" 
        bottom='5vh' 
        backgroundColor='rgba(0,0,0,0.5)'
         textColor='white'
          height='4vh'
          onClick={() => { setViewComponent('Find') }}>
          비밀번호 찾기
        </Button>

      </Flex>
      <Underbar color='rgba(0,0,0,0.5)' />
    </Flex >
  );
}
