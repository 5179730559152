// React
import React, { useEffect, useState } from "react";
import { Flex, Box, Image, Text, Button, useColorModeValue } from "@chakra-ui/react";
import axios from 'axios';
// Components
import '../../../css/main.css';
import main_store from "../../../assets/img/service/main_store.png";
import main_sale from "../../../assets/img/service/main_sale.png";
import main_transaction from "../../../assets/img/service/main_transaction.png";
import main_result from "../../../assets/img/service/main_result.png";
import MainLogoutModal from "./main/modal/MainLogoutModal";
import MainClosingModal from "./main/modal/MainClosingModal";
import ClosingFinishModal from "./main/modal/ClosingFinishModal";
//external
import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';
import Loading from "./main/component/Loading";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function MainCard({ setNowComponent, orderList, userInfo, setViewComponent, storeInfo, setMainCall, setSecondPwdModalOpen, setNextFunction, secondCheck, nextFunction, totalResultSalesAmount, setSecondCheck }) {
    const [mainComponent, setMainComponent] = useState('');

    //modal status
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);
    const [closingModalOpen, setClosingModalOpen] = useState(false);
    const [closingFinishModalOpen, setClosingFinishModalOpen] = useState(false);
    const [closeAndLogout, setCloseAndLogout] = useState(false);
    // const [sirenOrderModal, setSirenOrderModal] = useState(false);
    const nowDetailDate = format(new Date(), 'yyyy-MM-dd (eee) HH:mm', { locale: ko })

    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function logout() {
        localStorage.removeItem('jwtToken');
        for(let i = 0; i < localStorage.length; i++) {
            // key 찾기  
            const key = localStorage.key(i);    
            // value 찾기  
            const value = localStorage.getItem(key);    
            if(value.includes("pwd")){
                localStorage.removeItem(key);
            }
          }
        setViewComponent('');
    }
    useEffect(() => {
        if (secondCheck) {
            setMainComponent('loading');
            if (nextFunction === 'closingFinish') {
                setClosingModalOpen(false);
                axios.post('/api/store/close', {
                    today_sales: totalResultSalesAmount
                })
                    .then((response) => {
                        setMainComponent('');
                        setMainCall(true);
                        setClosingFinishModalOpen(true);
                        setSecondCheck(false);
                    })
                    .catch(() => {
                        setMainCall(true);
                        setClosingFinishModalOpen(true);
                        setSecondCheck(false);
                        setMainComponent('');
                    })
            } else if (nextFunction === 'closingFinishAndLogout') {
                setClosingModalOpen(false);
                axios.post('/api/store/close', {
                    today_sales: totalResultSalesAmount
                })
                    .then((response) => {
                        setMainComponent('');
                        setMainCall(true);
                        setClosingFinishModalOpen(true);
                        setCloseAndLogout(true);
                        setSecondCheck(false);
                    })
                    .catch(() => {
                        setMainComponent('');
                        setMainCall(true);
                        setClosingFinishModalOpen(true);
                        setCloseAndLogout(true);
                        setSecondCheck(false);
                    })
            }
        }
    }, [secondCheck])

    function secondModal(functionName) {
        setNextFunction(functionName);
        setSecondPwdModalOpen(true);
    }

    function openAndClose() {
        if (storeInfo.open_status === 1) {
            setClosingModalOpen(true);
        } else if (storeInfo.open_status === 0) {
            setViewComponent("Open");
        }
    }
    return (
        mainComponent === 'loading' ?
            <Loading />

            :
            <Box
                width='100%'
                height='100%'
                align='center'
                position='relative'
                borderRadius='20px 20px 0 0'
                borderTop='1px solid white'
                // backgroundColor='rgba(100,200,220,1)'
                className ='sub-background-color'
            >
                {logoutModalOpen && <MainLogoutModal logout={logout} onClose={() => setLogoutModalOpen(false)} />}
                {closingModalOpen && <MainClosingModal userInfo={userInfo} orderList={orderList} nowDetailDate={nowDetailDate} onClose={() => setClosingModalOpen(false)} secondModal={secondModal} storeInfo={storeInfo} totalResultSalesAmount={totalResultSalesAmount} />}
                {closingFinishModalOpen && <ClosingFinishModal logout={logout} onClose={() => setClosingFinishModalOpen(false)} closeAndLogout={closeAndLogout} />}

                {
                    userInfo.authority === 'master' || userInfo.authority_sales === '1' ?
                        <Flex
                            className='mainboardCard'
                            top={{ base: '5vh', lg: '5vh' }}
                            borderRadius='0px'
                            backgroundColor='white'
                            border='1px solid rgba(193, 185, 185, 1)'
                            shadow={shadow}
                            align='center'
                            justifyContent='space-evenly'
                            position='relative'
                            onClick={() => { setNowComponent('sale') }}
                        >
                            <Image src={main_sale} className='mainboardCardImage' />
                            <Text
                                className='mainboardCardText'
                            >
                                판매 처리
                            </Text>
                        </Flex>
                        :
                        <Flex
                            className='mainboardCard'
                            top={{ base: '5vh', lg: '5vh' }}
                            borderRadius='0px'
                            backgroundColor='white'
                            border='1px solid rgba(193, 185, 185, 1)'
                            shadow={shadow}
                            align='center'
                            justifyContent='space-evenly'
                            position='relative'

                        >
                            <Image src={main_sale} className='mainboardCardImage' />
                            <Text
                                className='mainboardCardText'
                            >
                                권한 필요
                            </Text>
                        </Flex>
                }
                {
                    userInfo.authority === 'master' || userInfo.authority_transaction === '1' ?
                        <Flex
                            className='mainboardCard'
                            my='24px'
                            top={{ base: '5vh', lg: '5vh' }}
                            borderRadius='0px'
                            backgroundColor='white'
                            border='1px solid rgba(193, 185, 185, 1)'
                            shadow={shadow}
                            align='center'
                            justifyContent='space-evenly'
                            position='relative'
                            onClick={() => { setNowComponent('transaction') }}
                        >
                            <Image src={main_transaction} className='mainboardCardImage' />
                            <Text
                                className='mainboardCardText'
                            >
                                결산 관리
                            </Text>
                        </Flex>
                        :
                        <Flex
                            className='mainboardCard'
                            my='24px'
                            top={{ base: '5vh', lg: '5vh' }}
                            borderRadius='0px'
                            backgroundColor='white'
                            border='1px solid rgba(193, 185, 185, 1)'
                            shadow={shadow}
                            align='center'
                            justifyContent='space-evenly'
                            position='relative'
                        >
                            <Image src={main_transaction} className='mainboardCardImage' />
                            <Text
                                className='mainboardCardText'
                            >
                                권한 필요
                            </Text>
                        </Flex>
                }
                {
                    userInfo.authority === 'master' || userInfo.authority_store === '1' ?
                        <Flex
                            className='mainboardCard'
                            mb='24px'
                            top={{ base: '5vh', lg: '5vh' }}
                            borderRadius='0px'
                            backgroundColor='white'
                            border='1px solid rgba(193, 185, 185, 1)'
                            shadow={shadow}
                            align='center'
                            justifyContent='space-evenly'
                            position='relative'
                            onClick={() => { setNowComponent('store') }}
                        >
                            <Image src={main_store} className='mainboardCardImage' />
                            <Text
                                className='mainboardCardText'
                            >
                                매장 관리
                            </Text>
                        </Flex>
                        :
                        <Flex
                            className='mainboardCard'
                            mb='24px'
                            top={{ base: '5vh', lg: '5vh' }}
                            borderRadius='0px'
                            backgroundColor='white'
                            border='1px solid rgba(193, 185, 185, 1)'
                            shadow={shadow}
                            align='center'
                            justifyContent='space-evenly'
                            position='relative'
                        >
                            <Image src={main_store} className='mainboardCardImage' />
                            <Text
                                className='mainboardCardText'
                            >
                                권한 필요
                            </Text>
                        </Flex>
                }
                {
                    userInfo.authority === 'master' || userInfo.authority_calculation === '1' ?
                        <Flex
                            className='mainboardCard'
                            top={{ base: '5vh', lg: '5vh' }}
                            my='24px'
                            borderRadius='0px'
                            backgroundColor='white'
                            border='1px solid rgba(193, 185, 185, 1)'
                            shadow={shadow}
                            align='center'
                            justifyContent='space-evenly'
                            position='relative'
                            onClick={() => { setNowComponent('calculation') }}
                        >
                            <Image src={main_result} className='mainboardCardImage' />
                            <Text
                                className='mainboardCardText'
                            >
                                정산 관리
                            </Text>
                        </Flex>
                        :
                        <Flex
                            className='mainboardCard'
                            top={{ base: '5vh', lg: '5vh' }}
                            my='24px'
                            borderRadius='0px'
                            backgroundColor='white'
                            border='1px solid rgba(193, 185, 185, 1)'
                            shadow={shadow}
                            align='center'
                            justifyContent='space-evenly'
                            position='relative'
                        >
                            <Image src={main_result} className='mainboardCardImage' />
                            <Text
                                className='mainboardCardText'
                            >
                                권한 필요
                            </Text>
                        </Flex>
                }
                {/* <Flex
                    className='mainboardCard'
                    top={{ base: '5vh', lg: '5vh' }}
                    borderRadius='0px'
                    backgroundColor='white'
                    border='1px solid rgba(193, 185, 185, 1)'
                    shadow={shadow}
                    align='center'
                    justifyContent='space-evenly'
                    position='relative'
                    onClick={() => { setViewComponent('BackOffice') }}
                >
                     <Image src={chart1} className='mainboardCardImage' />
                    <Text
                        className='mainboardCardText'
                    >
                        통계 관리
                    </Text>
                </Flex> */}
                <Flex
                    justifyContent='space-evenly'
                    position='fixed'
                    top='90vh'
                    width='100%'
                >
                    <Button
                        border='1px solid'
                        borderColor='#C1B9B9'
                        borderRadius='0px'
                        width='40%'
                        className="mainBtnText"
                        onClick={() => { setLogoutModalOpen(true) }}
                    >
                        종료
                    </Button>
                    <Button
                        border='1px solid'
                        borderColor='#C1B9B9'
                        borderRadius='0px'
                        width='40%'
                        className="mainBtnText"
                        onClick={() => { openAndClose() }}
                    >
                        {
                            storeInfo && storeInfo.open_status === 1 ?
                                "마감처리"
                                :
                                "영업개시"
                        }
                    </Button>
                </Flex>
            </Box>
    );
}
